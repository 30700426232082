import React, { useState } from "react"
import { Link } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GoogleMap, LoadScript } from "@react-google-maps/api"
import { Marker, InfoWindow } from "@react-google-maps/api"
const mapContainerStyle = {
  width: "100%",
  minHeight: "400px",
}

const locations = [
  {
    //29 Leng Kee
    lat: 1.2908205259594545,
    lng: 103.81126240304896,
  },
  {
    //11 Kung Chung
    lat: 1.2898246688264143,
    lng: 103.81178946735292,
  },
  {
    //11 Leng Keng 
    lat: 1.2905742539068492,
    lng: 103.81359133282957,
  },
]
const divStyle = {
  background: `white`,
  padding: `3px`,
  //minHeight: `150px`,
}
const LocatePage = ({ data, location }) => {
  const [marker, setMarker] = useState("");
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content

  return (
    <Layout >
      <Nav header="nomenu" urlParam={url_param}/>
      <div className="no-masthead relative"></div>

      <section className="section sectionFirst">
        <div className="container py-10">
          <h1 className="text-2xl lg:text-4xl font-light uppercase mb-6 md:mb-12">
            Locate Us.
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-12">
            <div className="md:col-span-5">
              <h2 className="mb-1 font-bold underline">RETAIL LOCATIONS</h2><br />
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">BMW Eurokars Experience Centre</h3>
                <p>11 Kung Chong Road</p>
                <p>Singapore 159147</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                <p>Monday to Saturday: 8:30am – 7:00pm</p>
                <p>Sunday and Public Holidays: 10:00am – 6:00pm</p><br />
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">BMW Eurokars Auto Showroom</h3>
                <p>11 Leng Kee Road</p>
                <p>Singapore 159091</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                <p>Monday to Saturday: 8:30am – 7:00pm</p>
                <p>Sunday and Public Holidays: 10:00am – 6:00pm</p><br />
              </div>
              
              <h2 className="mt-5 md:mt-8 mb-1 font-bold underline">SERVICE LOCATIONS</h2><br />
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">BMW Eurokars Authorised Service Centre</h3>
                <p>11 Kung Chong Road</p>
                <p>Singapore 159147</p>
              </div>

              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                
                <p>Monday to Friday: 8:00am – 6:00pm</p>
                <p>Saturday: 8:00am – 12:00pm</p>
                <p>Sunday and Public Holidays: Closed</p><br />
              </div>

              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">BMW Eurokars Authorised Service Centre</h3>
                <p>29 Leng Kee Road</p>
                <p>Singapore 159099</p>
              </div>

              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                
                <p>Monday to Friday: 8:00am – 6:00pm</p>
                <p>Saturday: 8:00am – 12:00pm</p>
                <p>Sunday and Public Holidays: Closed</p><br />
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">BMW Eurokars Fast Lane Service Centre<br />(Certified Body & Paint Shop)
                </h3>
                <p>227A Tanjong Penjuru, Level 1, </p>
                <p>Singapore 609042</p>
              </div>

              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                
                <p>Monday to Friday: 8:00am – 6:00pm</p>
                <p>Saturday: 8:00am – 12:00pm</p>
                <p>Sunday and Public Holidays: Closed</p><br />
              </div>
              {/* <div className="mb-3 md:mb-5">
                <h4 className="font-bold">BMW Parts Counter</h4>
                <p>Monday to Thursday: 8:30am – 6:00pm</p>
                <p>Friday: 8:30am – 5:30pm</p>
                <p>Saturday: 8:30am – 12:30pm</p>
                <p>Sunday and Public Holidays: Closed</p>
              </div> */}
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">You may also reach us by:</h3>
                <ul className="list-disc list-inside">
                  <li>
                    Phone: <a href="tel:+6562198388">6219 8388</a>
                  </li>
                  <li>
                    WhatsApp:{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=6583830866&text="
                      target={`_blank`}
                      rel={`noreferer`}
                    >
                      8383 0866
                    </a>
                  </li>
                  <li>
                    Submitting an online form{" "}
                    <Link to={"/contact-us/" + url_param} className="underline">
                      here
                    </Link>
                    .
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-7">
              <LoadScript googleMapsApiKey="AIzaSyCeiEkZfeInSSxVcFQR9QSoPtfZtPfIvZ0">
                <GoogleMap
                  id="marker-example"
                  mapContainerStyle={mapContainerStyle}
                  zoom={16}
                  center={locations[0]}
                >
                  <Marker
                    position={locations[0]}
                    onClick={() => setMarker("0")}
                  />
                  <Marker
                    position={locations[1]}
                    onClick={() => setMarker("1")}
                  />
                  <Marker
                    position={locations[2]}
                    onClick={() => setMarker("2")}
                  />

                  {marker === "0" && (
                    <InfoWindow
                      onCloseClick={() => setMarker("")}
                      position={locations[0]}
                    >
                      <div className="" style={divStyle}>
                        <p>
                          <span className="font-bold">BMW Eurokars Auto Authorised Service Centre</span>
                          <br />
                          <a
                            href="https://maps.app.goo.gl/tKtgexVoCPhyBiRq5"
                            className="underline"
                            target={`_blank`}
                          >
                            29 Leng Kee Road Singapore 159099
                          </a>
                        </p>
                      </div>
                    </InfoWindow>
                  )}

                  {marker === "1" && (
                    <InfoWindow
                      onCloseClick={() => setMarker("")}
                      position={locations[1]}
                    >
                      <div className="" style={divStyle}>
                        <p>
                          <span className="font-bold">
                            BMW Eurokars Experience Centre / Authorised Service Centre
                          </span>
                          <br />
                          <a
                            href="https://maps.app.goo.gl/qA5eexHgFnUr1Qy36"
                            className="underline"
                            target={`_blank`}
                          >
                            11 Kung Chong Road Singapore 159147
                          </a>
                        </p>
                      </div>
                    </InfoWindow>
                  )}

                  {marker === "2" && (
                    <InfoWindow
                      onCloseClick={() => setMarker("")}
                      position={locations[2]}
                    >
                      <div className="" style={divStyle}>
                        <p>
                          <span className="font-bold">
                            BMW Eurokars Auto Showroom
                          </span>
                          <br />
                          <a
                            href="https://maps.app.goo.gl/fuR6q2CBL3wPj69C6"
                            className="underline"
                            target={`_blank`}
                          >
                            11 Leng Kee Road Singapore 159091
                          </a>
                        </p>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}

export default LocatePage

const seoDetails = {
  title: "Eurokars Auto | Locate Us",
  description: "Locate our BMW Showroom and Authorised Service Centre.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-locate-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
